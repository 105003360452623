<template>
  <div>
    <div class="article">
      <el-form :model="article" class="mu-demo-form" label-width="100">
        <el-form-item label="标题">
          <el-input v-model="article.title"></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <input type="file" @change="getFile">
            <img :src="selectedImage" alt="选择图片" class="thumb-image">
        </el-form-item>
        <el-form-item label="内容">
          <post-editor @onChange="handelContentChange" :content-prop="this.article.content" />
        </el-form-item>
        <el-form-item label="标签">
          <el-checkbox-group v-model="article.tag">
            <el-checkbox v-for="item of tags" :key="item.id" :label="item.name"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="articleSubmit()">提交修改</el-button>
    </div>
  </div>
</template>
<script>
import PostEditor from '../../../components/postEditor/postEditor.vue'
import { config } from '@/assets/config'
export default {
  name: 'AdminArticleAdd',
  components: {PostEditor},
  data () {
    return {
      id: this.$route.query.id,
      prefix: config.prefix,
      article: {
        title: '',
        content: '',
        tag: []
      },
      tags: [],
      selectedImage: ''
    }
  },
  methods: {
    getArticle () {
      this.$axios.get('/api/article/' + this.id)
        .then(res => {
          this.article = res.data
          this.selectedImage = `${this.prefix}/${res.data.imgurl}`
          this.article.imgurl = ''
          // 标记是否已经选择
          for (let i = 0; i < this.article.tag.length; i++) {
            this.article.tag[i] = this.article.tag[i].name
          }
        })
        .catch(err => console.log(err))
    },
    getTags () {
      this.$axios.get('/api/tag')
        .then(res => {
          this.tags = res.data
        })
        .catch(err => console.log(err))
    },
    articleSubmit () {
      const editedPost = {...this.article, tag: this.tags.filter(({name}) => this.article.tag.includes(name)).map(({ id }) => id)}
      this.$axios.put('/api/article/' + this.article.id, editedPost)
        .then(() => this.$router.push({ path: '/admin/article' }))
        .catch(err => console.log(err))
    },
    getFile (e) {
      const file = e.target.files[0]
      // image validation
      let arr = file.type.split('/')
      let valid = arr[0] === 'image' && (arr[1] === 'png' || arr[1] === 'jpg' || arr[1] === 'jpeg')
      if (!valid) {
        alert('选择图片格式错误')
        return
      }
      this.article.imgurl = file
      // show selected image
      let reader = new FileReader()
      let that = this
      reader.onload = function () {
        that.selectedImage = this.result
      }
      reader.readAsDataURL(file)
    },
    handelContentChange (content) {
      this.article.content = content;
    }
  },
  mounted () {
    this.getTags()
    this.getArticle()
  },
}
</script>
<style lang="stylus" scoped>
.article
  padding-top 100px
  padding-left 20px
  .thumb-image
    max-width 100px
    max-height 100px
    object-fit cover
</style>
